
.main{
	background-color:#ffffff;
}
.deviceLine{
    border-left: 1px solid #e4e5e6;
    margin-left:4px;
    margin-top:-16px;
    margin-bottom: -15px;
  }
  .deviceMoving{
    display:inline-block;
    margin:10px;
  }
  .device {
  /*position: relative;*/
  display: inline-block;
  /*width: 36px;
  height: 36px;*/ }
  .device .device-status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50em; }

.sidebar .nav-item a{
    display: block;
    color: #fff;
    padding: 6px 6px;
    text-decoration: none;
    background: transparent;
}
.sidebar .nav{
	background-color: #e4e5e6
  /*background-color: #cfe5fb;*/
}
.sidebar .nav .nav-title{
	color:#000000;
}
.sidebar .nav .nav-item a{
	color:#000;
}
.sidebar .nav .nav-link.active {
    color: #fff;
    background: #20a8d8;
}
.sidebar .nav .nav-link.active .nav-icon {
    color: #fff
}
.app-body .sidebar .sidebar-minimizer{
	background-color: #CAC8C8;
  /*background-color: #9ce1f5;*/
}

.app-body .sidebar .sidebar-minimizer:hover{
	background-color: #20a8d8;
}
.load-button{
	width:20%;
	display:block;
	margin:auto;
}
.a-h5{
  color: #ffffff;
}
.register-message{
    text-align: center;
    color: #d26a6a;
}

.nav-tabs .nav-link {
    padding-left: 0px;
}

.driver-analysis{
  text-align: center;
  margin-top:10px;
  color: #73818f;
}
.vehicle-name{
  text-align: center;
  color: #73818f;
}
.driver-stats{
  color: #73818f;
}
.driver-name{
  text-transform: uppercase;
  color: #969696;
}
.driver-heading{
  margin-bottom:15px;
}
.driver-register{
  margin-top: 10px;
  margin-bottom:10px;
}
.move-back{
  margin-bottom:10px;
}
.username-align{
  margin-top: 2px;
}
